<template>
  <div>
    <div class="searchWrapper" @keydown.enter.prevent="">
      <el-form
        :inline="true"
        status-icon
        label-position="right"
        label-width="80px"
        :model="formInline"
        ref="formInline"
        class="demo-form-inline"
      >
        <el-form-item :label="$t('searchModule.region')" prop="areaId">
          <a-cascader ref="cascader" @change="parkClear"></a-cascader>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Belonging_operator')">
          <a-operation-select ref="operationSelect" @change="parkClear"></a-operation-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
          <a-park-type-select ref="parkTypeSelect" @change="parkClear"></a-park-type-select>
        </el-form-item>
        <!-- <el-form-item :label="$t('searchModule.region')">
          <el-select v-model.trim="formInline.areaId"
                    @change='areaChange'
                    filterable
                    size="15"
                    placeholder="请选择">
            <el-option label="全部"
                      value=""></el-option>
            <el-option :label="area.areaName"
                      :value="area.areaId"
                      :key="area.areaId"
                      v-for="area in areaList"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.region')">
          <el-select v-model.trim="formInline.streetId"
                    @change="streetChange"
                    filterable
                    size="15"
                    placeholder="请选择">
            <el-option label="全部"
                      value=""></el-option>
            <el-option :label="area.areaName"
                      :value="area.areaId"
                      :key="area.areaId"
                      v-for="area in areaList2"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item :label="$t('searchModule.Road_or_Parking_Lot')">
          <a-park-select
            ref="parkSelect"
            :instance="this"
            parkTypeRefName="parkTypeSelect"
            operationRefName="operationSelect"
            areaRefName="cascader"
          ></a-park-select>
        </el-form-item>
        <!-- <el-form-item :label="$t('searchModule.charge_type')">
          <el-select v-model.trim="formInline.scopeId" filterable size="15">
            <el-option label="全部" value=""></el-option>
            <el-option label="一类区" value="1"></el-option>
            <el-option label="二类区" value="2"></el-option>
            <el-option label="三类区" value="3"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item :label="$t('searchModule.Date_search')">
          <a-date-picker ref="datePicker" :selectkeys="selectkeys"></a-date-picker>
        </el-form-item>
        <!-- <el-form-item :label="$t('searchModule.date')" prop="dateType" style="width: 200px">
          <el-select v-model.trim="formInline.dateType" filterable size="15" style="width: 100px">
            <el-option label="日" value="day">日</el-option>
            <el-option label="月" value="month">月</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="date">
          <timeRangePick
            @timeChange="timeChange"
            ref="timeRangePicker"
            :defalutDate="defaultTime"
            :type="formInline.dateType == 'day' ? 'daterange' : 'monthrange'"
            :valueFormat="formInline.dateType == 'day' ? 'yyyy-MM-dd' : 'yyyy-MM'"
          />
        </el-form-item> -->
        <!-- <el-date-picker v-model="startTime"
                        :picker-options="pickerOptions"
                        type="date"
                        placeholder="选择日期"
                        v-show="formInline.dateType == 'day'"
                        style="margin-right:10px;"></el-date-picker>
        <span v-show="formInline.dateType == 'day'">至</span>
        <el-date-picker v-model="endTime"
                        :picker-options="pickerOptions"
                        type="date"
                        placeholder="选择日期"
                        v-show="formInline.dateType == 'day'"
                        style="margin-right:10px;margin-left: 10px;"></el-date-picker>
        <el-date-picker v-model="startTime"
                        :picker-options="pickerOptions"
                        type="month"
                        placeholder="选择月"
                        v-show="formInline.dateType == 'month'"
                        style="margin-right:10px;"></el-date-picker>
        <span v-show="formInline.dateType == 'month'">至</span>
        <el-date-picker v-model="endTime"
                        :picker-options="pickerOptions"
                        type="month"
                        placeholder="选择月"
                        v-show="formInline.dateType == 'month'"
                        style="margin-right:10px;"></el-date-picker> -->
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="searchNightParkList"
          :loading="loading"
          style="margin: 0 0 13px 13px"
          >查询
        </el-button>
        <el-button type="primary" icon="el-icon-refresh" @click="resetForm()" :loading="loading"
          >{{ $t('button.reset') }}</el-button
        >
        <el-button
          :loading="loading"
          type="primary"
          @click="exportFileList"
          v-if="$route.meta.authority.button.export"
          >{{ $t('button.export') }}</el-button
        >
      </el-form>
    </div>
    <div class="content">
      <!--折线图-->
      <CircularChart
        :nightParktableData="tableData"
        :type="type3"
        class="graphShadow paddingT10 paddingLR20 marginTB20"
      ></CircularChart>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table v-loading="loading" :data="tableData_" style="width: 100%">
          <el-table-column prop="_dataDate" :label="$t('searchModule.date')" width="" align="center"> </el-table-column>
          <el-table-column label="日间" align="center">
            <el-table-column
              align="center"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              v-for="item in tableCols1"
              :key="item.prop"
              :formatter="item.formatter"
            ></el-table-column>
          </el-table-column>
          <el-table-column label="夜间" align="center">
            <el-table-column
              align="center"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              v-for="item in tableCols"
              :key="item.prop"
              :formatter="item.formatter"
            ></el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
// import timeRangePick from "@/components/timePicker";
// 折线图
import CircularChart from "./circularChart5";
import myComponent from "@/components/autocomplete/myautoComponent";
import { exportExcelNew, formatDuring, formatWeekDate } from "@/common/js/public.js";

export default {
  name: "nightPark",
  data() {
    const startTime = new Date();
    startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
    const endTime = new Date();
    endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
    return {
      tableData_: [],
      selectkeys: ["date", "month", "week", "year"],
      defaultTime: [startTime, endTime],
      // 区域列表
      areaList: [],
      // 区域街道
      areaList2: [],
      tableData: [],
      // 计费类型
      billingTypes: [],
      loading: false,
      // pickerOptions: {
      //   disabledDate (time) {
      //     return time.getTime() > endTime;
      //   }
      // },
      formInline: {
        // 区域
        areaId: "",
        // 区域
        // streetId: "",
        // 车场ID
        parkId: "",
        // 车场名称
        parkName: "",
        // 计费类型
        // scopeId: "",
        startTime: "",
        endTime: "",
        dateType: "day",
      },
      // 折线图样式
      type3: "c3",
      type2: "c2",
      tableCols1: [
        {
          prop: "daytimeSeconds",
          label: "日间停车时长",
          width: "",
          formatter: (row) => {
            return formatDuring(row.daytimeSeconds, "seconds");
            // let day = parseInt(row.daytimeParkTime / 60 / 24);
            // let hour = parseInt((row.daytimeParkTime / 60) % 24);
            // let min = parseInt(row.daytimeParkTime % 60);
            // let StatusMinute = 0;
            // if (day > 0 || hour > 0 || min > 0) {
            //   StatusMinute = day + "天" + hour + "小时" + min + "分钟";
            // }
            // return StatusMinute;
          },
        },
      ],
      tableCols: [
        // {
        //   prop: "dataDate",
        //   label: this.$t("list.date"),
        //   width: "",
        // },
        {
          prop: "nighttimeSeconds",
          label: "夜间停车时长",
          width: "",
          formatter: (row) => {
            return formatDuring(row.nighttimeSeconds, "seconds");
            // let day = parseInt(row.nightParkTime / 60 / 24);
            // let hour = parseInt((row.nightParkTime / 60) % 24);
            // let min = parseInt(row.nightParkTime % 60);
            // let StatusMinute = 0;
            // if (day > 0 || hour > 0 || min > 0) {
            //   StatusMinute = day + "天" + hour + "小时" + min + "分钟";
            // }
            // return StatusMinute;
          },
        },
        {
          prop: "pureNighttimeCount",
          label: "纯夜间停车次数(次)",
          width: "",
        },
        {
          prop: "pureNighttimeSeconds",
          label: "纯夜间停车时长",
          width: "",
          formatter: (row) => {
            return formatDuring(row.pureNighttimeSeconds, "seconds");
            // let day = parseInt(row.pureNightParkTime / 60 / 24);
            // let hour = parseInt((row.pureNightParkTime / 60) % 24);
            // let min = parseInt(row.pureNightParkTime % 60);
            // let StatusMinute = 0;
            // if (day > 0 || hour > 0 || min > 0) {
            //   StatusMinute = day + "天" + hour + "小时" + min + "分钟";
            // }
            // return StatusMinute;
          },
        },
      ],
    };
  },
  methods: {
    parkClear() {
      this.$refs.parkSelect.clear();
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    // 改变城市
    areaChange(item) {
      if (!item) {
        // this.formInline.streetId = "";
        this.areaList2 = [];
        return;
      }
      this.resetCarPark();
      this.getChildrenArea(item);
    },
    // 改变区域
    streetChange(item) {
      this.resetCarPark();
    },
    // 清空车场组件信息
    resetCarPark() {
      this.formInline.parkId = "";
      this.formInline.parkName = "";
      // this.$refs.parkInput.setShowVal("");
    },
    // 清空
    resetForm() {
      this.formInline.areaId = "";
      // this.formInline.streetId = "";
      // this.formInline.scopeId = "";
      this.formInline.dateType = "day";
      this.resetCarPark();
      // const startTime = new Date();
      // startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
      // const endTime = new Date();
      // endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
      // this.startTime = startTime
      // this.endTime = endTime
      this.$refs.cascader.clear();
      this.$refs.operationSelect.clear();
      this.$refs.datePicker.clear();
      this.$refs.parkTypeSelect.clear();
      this.parkClear();
    },
    // 初始化用户区域下拉列表（区域和街道）
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          // this.formInline.streetId = "";
        });
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    // 计费类型
    billingFn() {
      this.$axios.get("/acb/2.0/systems/loginUser/initScopeList").then((res) => {
        this.billingTypes = res.value.scopeList;
      });
    },
    // 查询夜间停车列表
    searchNightParkList() {
      this.loading = true;
      this.$axios
        .post("/acb/2.0/aceParkAnalysis/night", {
          data: {
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
            // streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
            // scopeId: this.formInline.scopeId, // 计费类型id
          },
        })
        .then((res) => {
          this.tableData = res.value;
          // 周粒度需要单独处理

          this.tableData.forEach((item) => {
            item._dataDate = item.dataDate;
            //   if (this.$refs.datePicker.getDateType() == 3) {
            //     if (item.dataDate) {
            //       item._dataDate = item.dataDate;
            //     } else {
            //       item._dataDate = item.dataDate;
            //     }
            //   } else {
            //     item._dataDate = item.dataDate;
            //   }
          });
          this.tableData_ = [...this.tableData];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // getTimeNow () {
    //   const startTime = new Date();
    //   const endTime = new Date();
    //   if (this.formInline.dateType == "day") {
    //     startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
    //     endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
    //   } else {
    //     startTime.setTime(startTime.getTime() - 365 * 24 * 3600 * 1000);
    //     endTime.setTime(endTime.getTime() - 31 * 24 * 3600 * 1000);
    //   }
    //   this.startTime = dateFormat(startTime, 'yyyy-MM-dd');
    //   this.endTime = dateFormat(endTime, 'yyyy-MM-dd');
    // },
    // 时间判断
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        let diff = "";
        if (this.formInline.dateType === "day") {
          if (time >= 31) {
            this.$alert("查询时间不能大于31天");
            return false;
          } else {
            return true;
          }
        } else {
          if (time > 365) {
            this.$alert("查询时间不能大于12个月");
            return false;
          } else {
            return true;
          }
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    getDateType() {
      let dateType = this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "";
      let val = "";
      switch (dateType) {
        case 1:
          val = "day";
          break;
        case 5:
          val = "week";
          break;
        case 2:
          val = "month";
          break;
        case 3:
          val = "year";
          break;
        default:
          break;
      }
      return val;
    },
    // 导出
    exportFileList() {
      const tempData = {
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
        // streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
        areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
        // scopeId: this.formInline.scopeId,
        dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
      };
      const url = "/acb/2.0/aceParkAnalysis/night/export";
      exportExcelNew(url, tempData);
    },
  },
  created() {
    this.getArea();
    // this.getTimeNow();
  },
  mounted() {
    this.searchNightParkList();
  },
  components: {
    CircularChart,
    // timeRangePick,
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.searchWrapper {
  .el-form {
    padding: 22px 22px 0;
  }
}

.content {
  overflow: hidden;
}
</style>
